<template>
  <div class="achievement-header d-flex mb-5">
    <div :class="`achievement-header-wrapper wrapper-1 ${level.style.bgColor}`">
      <img src="@/assets/membership/pattern-1.svg" class="achievement-pattern pattern-1" alt="Pattern">
    </div>
    <div :class="`achievement-header-wrapper wrapper-2 ${level.style.bgColor}`">
      <img src="@/assets/membership/pattern-2.svg" class="achievement-pattern pattern-2" alt="Pattern">
    </div>
    <div class="achievement-info">
      <div
        class="achievement-circle d-flex align-items-center justify-content-center"
        :class="level.style.bgColorIcon"
      >
        <img :src="level.style.icon" class="achievement-circle-icon" alt="Icon">
      </div>
      <div class="achievement-description d-flex align-items-center justify-content-center gap-2">
        <span
          class="achievement-description-label fs-14 lh-24 fw-700"
          :class="level.style.textColor"
        >
          {{ level.style.name ?? 'Pasien Biasa' }}
        </span>
        <div class="bullet bg-neutral-400"></div>
        <span class="achievement-description-poin fs-14 lh-24 fw-400">{{ level.data.point }} Poin</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['level'],
  setup () {
    return {}
  }
}
</script>

<style scoped lang="scss">
  .achievement {
    &-pattern {
      position: absolute;
      z-index: 10;

      &.pattern-2 {
        right: 0;
        bottom: 0;
      }
    }

    &-header {
      width: 100%;
      height: 180px;
      position: relative;

      &-wrapper {
        width: 50%;
        height: 100%;
        position: relative;
        z-index: 1;

        &.wrapper-1::before {
          left: 0;
          border-right: solid v-bind('level.style.color');
          border-bottom: 15px solid white;
        }

        &.wrapper-2::before {
          right: 0;
          border-left: solid v-bind('level.style.color');
          border-bottom: 15px solid white;
        }

        // mobile
        @media (min-width: 360px) and (max-width: 520px) {
          &.wrapper-1::before {
            border-right-width: 200px;
          }

          &.wrapper-2::before {
            border-left-width: 200px;
          }
        }

        // ipad
        @media (min-width: 521px) and (max-width: 768px) {
          &.wrapper-1::before {
            border-right-width: 400px;
          }

          &.wrapper-2::before {
            border-left-width: 400px;
          }
        }

        // desktop
        @media (min-width: 769px) and (max-width: 1024px) {
          &.wrapper-1::before {
            border-right-width: 500px;
          }

          &.wrapper-2::before {
            border-left-width: 500px;
          }
        }

        @media (min-width: 1025px) {
          &.wrapper-1::before {
            border-right-width: 710px;
          }

          &.wrapper-2::before {
            border-left-width: 710px;
          }
        }
      }

      &-wrapper::before {
        width: 0;
        content: '';
        position: absolute;
        bottom: 0;
      }
    }

    &-info {
      position: absolute;
      bottom: -50px;
      left: 0;
      right: 0;
      z-index: 10;
    }

    &-circle {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
      border: 6px solid white;

      &-icon {
        width: 35px;
      }
    }

    &-description {
      .bullet {
        width: 4px;
        height: 4px;
        border-radius: 50%;
      }
    }
  }
</style>
