<template>
  <!-- Reward Open -->
  <div class="row pb-4">
    <div class="col">
      <!-- Notif -->
      <!-- <div class="notification bg-info-50 d-flex align-items-center gap-2">
        <div class="icon-wrapper rounded-circle bg-info-500 d-flex align-items-center justify-content-center">
          <img
            src="@/assets/icon/info-circle.svg"
            alt=""
          >
        </div>
        <div class="fw-400 fs-12 lh-14 color-info-500">
          Poin dan reward berlaku hingga <span class="fw-700">31 Desember 2022</span>
        </div>
      </div> -->

      <!-- Reward -->
      <h3 class="fs-18 lh-28 fw-700 mb-2">Reward</h3>
      <div class="d-flex flex-column gap-3">

        <router-link
          v-for="reward in rewards"
          :key="reward.id_dt_member"
          :to="{
            name: 'DetailReward',
            params: { idMemberDt: reward.id_dt_member },
            query: {
              id_dt_member: reward.id_dt_member,
              jenis: reward.jenis,
              keterangan: reward.keterangan,
              kode: reward.kode,
              status: reward.status,
              tgl_klaim: reward.tgl_klaim,
              tgl_reedem: reward.tgl_reedem,
              icon: reward.icon,
            }
          }"
          class="card card-reward"
          :class="`${style.bgColor}`">
          <div class="card-body text-white">
            <div class="d-flex align-items-start justify-content-between">
              <div class="reward-group d-flex flex-column gap-2 w-100">
                <span class="reward-label fs-12 fw-300 lh-24">Reward</span>
                <h5 class="reward-description fw-700 mb-0">{{ reward.keterangan }}</h5>
                <!-- <span class="fs-10 lh-12 fw-400">Ini contoh banner reward</span> -->
              </div>
              <img
                width="64"
                :src="reward.icon"
                alt="Reward"
              >
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>

  <!-- Reward Locked -->
  <div class="row bg-neutral-100 py-2">
    <div class="col">
      <div class="title mb-3">
        <div class="d-flex align-items-center gap-1">
          <h3 class="fs-18 lh-28 fw-700 color-neutral-600 mb-0">Reward Terkunci</h3>
          <img
            width="30"
            src="@/assets/icon/lock-circle.svg"
            class="icon-reward"
            alt="Icon Lock"
          >
        </div>
        <span class="fs-12 lh-22 fw-400 color-neutral-600">Top wallet dan dapatkan poin untuk mendapatkan reward</span>
      </div>
      <div class="d-flex flex-column gap-3">
        <div
          v-for="reward in rewardLocked"
          :key="reward.id_dt_member"
          class="card card-reward-locked bg-neutral-400"
        >
          <div class="card-body text-white">
            <img
              width="50"
              src="@/assets/icon/lock-circle-black.svg"
              class="icon-locked"
              alt="Locked"
            >
            <div class="d-flex align-items-start justify-content-between">
              <div class="reward-group d-flex flex-column gap-2">
                <span class="reward-label fs-12 fw-300 lh-24">Reward</span>
                <h5 class="reward-description fw-700 mb-0">{{ reward.keterangan }}</h5>
                <!-- <span class="fs-10 lh-12 fw-400">Ini contoh banner reward</span> -->
              </div>
              <img
                width="64"
                :src="reward.icon"
                class="icon-reward-locked"
                alt="Reward"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['rewards', 'style', 'rewardLocked'],
  setup () {
    return {}
  }
}
</script>

<style lang='scss' scoped>
.card-reward {
  border: none;
  border-radius: 12px;

  .card-body {
    padding: 14px;

    .reward {
      &-group {
        @media (max-width: 520px) {
          width: 15rem !important;
        }
      }

      &-description {
        @media (max-width: 520px) {
          font-size: 16px;
        }
      }
    }
  }
}

.card-reward-locked {
  border: none;
  border-radius: 12px;

  .card-body {
    position: relative;

    .reward {
      &-group {
        @media (max-width: 520px) {
          width: 15rem !important;
        }
      }

      &-description {
        @media (max-width: 520px) {
          font-size: 16px;
        }
      }
    }
  }
}

.icon-locked {
  position: absolute;
  z-index: 10;
  right: 23px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.icon-reward-locked {
  opacity: 0.75;
  color: rgba(0, 0, 0, 0.75);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  filter: blur(5px);
}

.notification {
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 24px;

  .icon-wrapper {
    padding: 4px;
    width: 26px;
    height: 26px;
  }
}
</style>
