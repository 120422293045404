<template>
  <section id="membership">
    <div
      v-if="loading.totalPoint || loading.levelPoint"
      class="loading d-flex align-items-center justify-content-center"
    >
      <Loading />
    </div>
    <div v-else>
      <achievement-header :level="level" />
      <div class="container">
        <app-bar
          title="Membership"
          previousLink="/"
          isBgTransparent
          noDivider
          :withBorderBottom="false" />

        <achievement-bar :levelPoint="levelPoint" :totalPoint="totalPoint" />
        <reward
          :rewards="rewardOpen"
          :rewardLocked="rewardLocked"
          :style="level.style"
        />
        <!-- <benefit /> -->
      </div>
    </div>
  </section>
</template>

<script>
import { nextTick, onMounted, ref } from '@vue/runtime-core'
import { title } from '@/core/page'
import AppBar from '@/components/AppBar.vue'
import AchievementHeader from '@/components/home/membership/AchievementHeader.vue'
import AchievementBar from '@/components/home/membership/AchievementBar.vue'
// import Benefit from '@/components/home/membership/Benefit.vue'
import Reward from '@/components/home/membership/Reward.vue'
import { totalPoint, levelPoint, getTotalPoint, getLevelPoint, loading } from '@/core/membership'
import Loading from '@/components/utility/Loading'

const achievements = [
  {
    name: 'Pasien Biasa',
    color: '#A0AEC0',
    textColor: 'color-neutral-600',
    bgColor: 'bg-neutral-500',
    bgColorIcon: 'bg-neutral-700',
    icon: require('@/assets/icon/progress-bar/lovely.svg')
  },
  {
    name: 'Friend',
    color: '#30AFC1',
    textColor: 'color-primary-500',
    bgColor: 'bg-primary-400',
    bgColorIcon: 'bg-primary-500',
    icon: require('@/assets/icon/progress-bar/like-shapes.svg')
  },
  {
    name: 'Buddy',
    color: '#F79643',
    textColor: 'color-secondary-500',
    bgColor: 'bg-secondary-500',
    bgColorIcon: 'bg-secondary-500',
    icon: require('@/assets/icon/progress-bar/like.svg')
  },
  {
    name: 'Bestie',
    color: '#ED535A',
    textColor: 'color-danger-500',
    bgColor: 'bg-danger-400',
    bgColorIcon: 'bg-danger-500',
    icon: require('@/assets/icon/progress-bar/emoji-happy.svg')
  },
  {
    name: 'Soulmate',
    color: '#7879F1',
    textColor: 'color-iris-100',
    bgColor: 'bg-iris-80',
    bgColorIcon: 'bg-iris-100',
    icon: require('@/assets/icon/progress-bar/lovely.svg')
  }
]

export default {
  components: { AppBar, AchievementHeader, AchievementBar, Reward, Loading },
  setup () {
    const level = ref({
      style: {
        name: 'Pasien Biasa',
        color: '#A0AEC0',
        textColor: 'color-neutral-600',
        bgColor: 'bg-neutral-500',
        bgColorIcon: 'bg-neutral-700',
        icon: require('@/assets/icon/progress-bar/lovely.svg')
      },
      data: {
        id_member: '',
        icon: '',
        name_member: '',
        point: 0,
        status_aktif: 0
      }
    })

    const onCheckPoint = () => {
      // totalPoint.value = 1800
      if (totalPoint.value < 200) {
        level.value.style = achievements[0]
      } else if (totalPoint.value >= 200 && totalPoint.value < 600) {
        level.value.style = achievements[1]
      } else if (totalPoint.value >= 600 && totalPoint.value < 1000) {
        level.value.style = achievements[2]
      } else if (totalPoint.value >= 1000 && totalPoint.value < 1500) {
        level.value.style = achievements[3]
      } else {
        level.value.style = achievements[4]
      }
    }

    const rewardOpen = ref([])
    const rewardLocked = ref([])

    onMounted(async () => {
      await getTotalPoint()
      await getLevelPoint()
      onCheckPoint()

      // check level point active
      const levelPointActive = levelPoint.value.filter((level) => Number(level.status_aktif) === 1)
      if (levelPointActive.length > 0) {
        levelPointActive.map((level) => {
          level.detail.map((val) => {
            rewardOpen.value.push(val)
          })
        })

        const lastLevel = levelPointActive.pop()
        level.value.data = {
          id_member: lastLevel.id_member,
          icon: lastLevel.icon,
          name_member: lastLevel.name_member,
          point: totalPoint.value,
          status_aktif: lastLevel.status_aktif
        }
      }

      const levelPointNonActive = levelPoint.value.filter((level) => Number(level.status_aktif) === 0)
      if (levelPointNonActive.length > 0) {
        levelPointNonActive.map((level) => {
          level.detail.map((val) => {
            rewardLocked.value.push(val)
          })
        })
      }

      nextTick(() => {
        title.value = 'Membership'
      })
    })

    return {
      totalPoint,
      levelPoint,
      level,
      rewardOpen,
      rewardLocked,
      loading
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  height: 100vh;
}

.card-reward {
  border: none;
  border-radius: 12px;

  .card-body {
    padding: 14px;
  }
}

.card-reward-locked {
  border: none;
  border-radius: 12px;

  .card-body {
    position: relative;
  }
}

.icon-locked {
  position: absolute;
  z-index: 10;
  right: 23px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.icon-reward-locked {
  opacity: 0.75;
  color: rgba(0, 0, 0, 0.75);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  filter: blur(5px);
}

.notification {
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 24px;

  .icon-wrapper {
    padding: 4px;
    width: 26px;
    height: 26px;
  }
}
</style>
