<template>
  <section id="achievement-bar" class="mb-3 mt-2">
    <div class="row">
      <div class="col-md-12 my-3">
        <div class="progress-wrapper mb-4">
          <div class="progress">
            <div
              v-for="level in levelPoint"
              :key="level.id_member"
              class="bar d-flex align-items-center justify-content-center"
              :class="[
                `bar-${level.nama_member.toLowerCase()}`,
                {
                  'active': Number(level.status_aktif) === 1
                }
              ]"
            >
              <img v-if="Number(level.status_aktif) === 1" :src="level.icon" class="icon" alt="">
              <img v-else src="@/assets/icon/progress-bar/record-circle.svg" class="icon" alt="">
            </div>

            <div v-if="levelPoint.length !== 4">
              <div
                v-for="(level, idx) in listLevelReverse.slice(0, (4 - levelPoint.length))"
                :key="idx"
                class="bar d-flex align-items-center justify-content-center"
                :class="[
                  `bar-${level.name.toLowerCase()}`,
                ]"
              >
                <img src="@/assets/icon/progress-bar/record-circle.svg" class="icon" alt="">
              </div>
            </div>

            <div class="progress-bar" :style="{ 'width': `${progressCalculate}px`, background: '#1A202C' }"></div>
          </div>
        </div>

        <div class="progress-description d-flex align-items-center justify-content-between">
          <div
            v-for="(level, idx) in listLevel"
            :key="idx"
            class="progress-description-item d-flex flex-column align-items-center gap-1">
            <label
              class="progress-description-item-label fs-10 lh-14"
              :class="[
                totalPoint >= level.point ? 'fw-400 color-neutral-700' : 'fw-300 color-neutral-500',
              ]"
            >
              {{ level.name }}
            </label>
            <span
              class="progress-description-item-poin fs-12 lh-14 fw-400 color-neutral-500"
              :class="[
                totalPoint >= level.point ? 'fw-500 color-neutral-700' : 'fw-400 color-neutral-500',
              ]"
            >
              {{ level.point }} Poin
            </span>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, ref } from 'vue'

export default {
  props: {
    levelPoint: {
      type: Array
    },
    totalPoint: {
      type: Number
    }
  },
  setup (props) {
    const listLevel = [
      {
        name: 'Friend',
        point: 200
      },
      {
        name: 'Buddy',
        point: 600
      },
      {
        name: 'Bestie',
        point: 1000
      },
      {
        name: 'Soulmate',
        point: 1500
      }
    ]
    const listLevelReverse = [
      {
        name: 'Soulmate',
        point: 1500
      },
      {
        name: 'Bestie',
        point: 1000
      },
      {
        name: 'Buddy',
        point: 600
      },
      {
        name: 'Friend',
        point: 200
      }
    ]
    const screenWidth = ref(window.innerWidth)
    const progress = ref(props.totalPoint)

    const progressCalculate = computed(() => {
      // check screen mode
      if (screenWidth.value <= 375) {
        if (progress.value <= 600) {
          // 600 point = 100px
          return (progress.value / 600) * 100
        } else if (progress.value > 600 && progress.value <= 1000) {
          return (progress.value / 1000) * 200
        } else {
          // 1500 point = 300px
          return (progress.value / 1500) * 300
        }
      } else if (screenWidth.value > 375 && screenWidth.value <= 768) {
        if (progress.value <= 600) {
          // 600 point = 210px
          return (progress.value / 600) * 210
        } else if (progress.value > 600 && progress.value <= 1000) {
          // 1000 point = 420px
          return (progress.value / 1000) * 420
        } else {
          // 1500 point = 630px
          return (progress.value / 1500) * 630
        }
      } else if (screenWidth.value > 768 && screenWidth.value <= 1024) {
        if (progress.value <= 600) {
          // 600 point = 290px
          return (progress.value / 600) * 290
        } else if (progress.value > 600 && progress.value <= 1000) {
          // 1000 point = 580px
          return (progress.value / 1000) * 580
        } else {
          // 1500 point = 900px
          return (progress.value / 1500) * 900
        }
      } else {
        if (progress.value <= 600) {
          // 600 point = 410px
          return (progress.value / 600) * 410
        } else if (progress.value > 600 && progress.value <= 1000) {
          // 1000 point = 820px
          return (progress.value / 1000) * 820
        } else {
          // 1500 point = 1250px
          return (progress.value / 1500) * 1250
        }
      }
    })

    onMounted(() => {
      window.onresize = () => {
        screenWidth.value = window.innerWidth
      }
    })

    return {
      progress,
      progressCalculate,
      screenWidth,
      listLevel,
      listLevelReverse
    }
  }
}
</script>

<style scoped lang="scss">
#achievement-bar {
  position: relative;

  .progress-wrapper {
    position: relative;
    width: 100%;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;

    .progress {
      height: 2px;

      .bar {
        position: absolute;
        margin-top: -10px;
        z-index: 1;
        width: 22px;
        height: 22px;
        border-radius: 25px;
        background: #EDF2F7;
      }

      .bar-friend {
        left: 1%;
      }

      .bar-buddy {
        left: 33%;
      }

      .bar-bestie {
        right: 33.5%;
      }

      .bar-soulmate {
        right: 1%;
      }

      .progress-bar {
        height: 2px;
      }

      .active {
        background-color: #1A202C;
      }

      @media (min-width: 360px) and (max-width: 520px) {
        .bar-friend {
          left: 4%;
        }

        .bar-buddy {
          left: 32.3%;
        }

        .bar-bestie {
          right: 33.5%;
        }

        .bar-soulmate {
          right: 4%;
        }
      }

      // ipad
      @media (min-width: 521px) and (max-width: 768px) {
        .bar-friend {
          left: 2%;
        }

        .bar-buddy {
          left: 32.5%;
        }

        .bar-bestie {
          right: 33%;
        }

        .bar-soulmate {
          right: 2%;
        }
      }
    }
  }

  .icon {
    width: 12px;
  }
}
</style>
